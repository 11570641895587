import "./menu.scss"


export default function Menu(prop) {
  return (
    <div className={"menu "+ (prop.menuOpen && "active")}>
        <ul>
            <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="#intro">Home</a>
            </li>
            {/* <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="#portfolio">Portfolio</a>
            </li>            
            <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="#works">Works</a>
            </li>            
            <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="#contact">Contact</a>
            </li>             */}
            <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="/about">About</a>
            </li>            
            <li onClick={()=>prop.setMenuOpen(false)}>
                <a href="/Projects">Projects</a>
            </li>            
        </ul>
    </div>
  )
}
