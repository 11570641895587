import './topbar.scss'
import {CoPresent} from '@mui/icons-material';

export default function Topbar(prop) {
  return (
    <div className={'topbar ' + (prop.menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href='#intro' className='logo'>
            DC
            <CoPresent/>
          </a>
          <div className="itemContainer">
            
            drk.cankaya@gmail.com
          </div>
          
        </div>
        
        <div className="right">
          <div className="hamburger" onClick={()=> prop.setMenuOpen(!prop.menuOpen)}>
            <span className='line1'></span>
            <span className='line2'></span>
            <span className='line3'></span>
          </div>
        </div>
      </div>
    </div>
  )
}
