import "./intro.scss";
import React, { useEffect, useRef } from "react";
import {KeyboardArrowDown} from '@mui/icons-material';
import { init } from 'ityped';

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    // console.log(textRef);
    init(textRef.current , {
      showCursor: true,
      strings: ["Hardware Engineer", "Software Designer", "Trader"],
      backDelay: 1500,
      backSpeed: 60,
    });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="assets/bg1.webp" alt=""/>
        </div>
      </div>
      <div className="right"> 
        <div className="wrapper">
          <h2>Hello, I'm </h2>
          <h1>Doruk Cankaya</h1>
          <h3>
            <span ref={textRef}></span>
          </h3>
          
        </div>
        <a href="#portfolio">
          <KeyboardArrowDown sx={{ fontSize: 80 }}/>
          
        </a>
      </div>

    </div>
  )
}
